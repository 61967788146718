/* eslint-disable max-len */
const projects = [
  {
    project: 'Transpare',
    desc: 'Plateforme de mise en relation entre importateurs / exportateurs et transitaires.',
    image: '/res/projects/transpare.png',
  },
  {
    project: 'Rofim',
    desc: 'Plateforme de télémédecine.',
    image: '/res/projects/rofim.png',
    link: 'https://rofim.fr',
  },
  {
    project: 'smsmode',
    desc: 'Plateforme d\'envoi de SMS B2C.',
    image: '/res/projects/smsmode.png',
    link: 'https://smsmode.com',
  },
]

export default projects
