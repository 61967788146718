/* eslint-disable max-len */
const projects = [
  {
    project: 'Corewar',
    desc: 'Compilateur / arène de combat de champions informatique.',
    link: 'https://github.com/MaximeFarrugia/CPE_Corewar_2018',
    tags: ['C', 'Assembly', 'Game', 'Memory'],
  },
  {
    project: 'Zappy',
    desc: "Reproduction d'un jeu de gestion d'un monde vivant et de ses habitants.",
    link: 'https://github.com/MaximeFarrugia/PSU_zappy_2019',
    tags: ['C', 'Python', 'Unity', 'IA', 'Game', 'Network'],
  },
  {
    project: 'AREA',
    desc: 'Détecter une action sur un service pour réaliser une action sur un autre service.',
    link: 'https://github.com/MaximeFarrugia/AREA',
    tags: ['React', 'NodeJS', 'Express', 'MongoDB', 'API', 'WebHooks'],
  },
  {
    project: 'Hackaton Jaguar Network',
    desc: 'Trouver des solutions pour la SmartMétropole.',
    link: 'https://community.jaguar-network.com/sprintoneweek-epitech-medinsoft-jaguar-network-3-projets-primes/',
    tags: ['React', 'Prisma', 'GraphQL', 'GMAO'],
  },
]

export default projects
