const experiences = [
  {
    company: 'Transpare',
    dates: [
      {
        start: 'Juill',
        end: 'Déc',
        yearStart: 2019,
        yearEnd: 2019,
      },
      {
        start: 'Juill',
        end: 'Mars',
        yearStart: 2020,
        yearEnd: 2021,
      },
    ],
    job: 'Développeur web',
  },
  {
    company: 'Rofim',
    dates: [
      {
        start: 'Avril',
        end: 'Juill',
        yearStart: 2021,
        yearEnd: 2021,
      },
    ],
    job: 'Développeur fullstack',
    link: 'https://rofim.fr',
  },
  {
    company: 'smsmode',
    dates: [
      {
        start: 'Mai',
        end: 'ajd',
        yearStart: 2022,
      },
    ],
    job: 'Ingénieur logiciel',
    link: 'https://smsmode.com',
  },
]

export default experiences
